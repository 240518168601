/* global jixAnalytics */
const form = document.getElementById('login_form');

form.addEventListener('submit', handleSubmitForm);

const stash = window.Stash['login/form'];

async function handleSubmitForm(e) {
    e.preventDefault();
    const formData = new FormData(form);

    const response = await fetch(stash.submit_url, {
        method: "POST",
        body: formData,
    });

    if (response.ok) {
        const result = await response.json();
        if (result.success) {
            const rememberButton = document.getElementById('remember_button');
            rememberButton.addEventListener('click', () => { submitStayLoggedIn(true, result.login_id, result.redirect_url); });
            const noButton = document.getElementById('no_button');
            noButton.addEventListener('click', () => { submitStayLoggedIn(false, undefined, result.redirect_url); });
            document.getElementById('form_box').hidden = true;
            const stayLoggedIn = document.getElementById('stay_logged_in_box');
            stayLoggedIn.hidden = false; // Show the stay logged in form
            stayLoggedIn.querySelector('#remember_button').focus();
        } else {
            showErrors(result);
        }
    } else {
        console.error('Login request failed');
    }
}

async function submitStayLoggedIn(remember, loginId, redirect_url) {
    if (remember) {
        jixAnalytics('event', { category: 'log-ind', action: 'click', label: 'husk-mig' });
        const response = await fetch(stash.remember_url, {
            method: 'POST',
            body: JSON.stringify({
                login_id: loginId,
            })
        });
        if (response.ok) {
            location.href = redirect_url || '/';
        } else {
            console.error('Request to stay logged in failed');
        }
    } else {
        location.href = redirect_url || stash.default_redirect_url;
    }
}

function showErrors(res) {
    for (let name of res.invalid_fields || []) {
        if (stash.only_password && name === 'email') {
            continue;
        }
        const field = form.querySelector(`input[name=${name}]`);
        field.classList.add('field-with-error');
        field.closest('.form-group').classList.add('has-error');
    }

    for (const e of form.getElementsByClassName('alert')) { e.remove(); }
    if (res.error_message) {
        const alertElement = document.createElement('div');
        alertElement.classList.add('alert', 'alert-danger');
        alertElement.setAttribute('role', 'alert');
        alertElement.textContent = res.error_message;
        form.prepend(alertElement);
    }
}
